import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function FormDialog(props) {
  //props should contain a default session if EDIT props.
  // 
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function handleAddOrEdit(jo) {
    
    // Code to handle login goes here

    props.addOrEditRowSession(jo)
    handleClose();
  
}
  return (
    <React.Fragment>
      
       <Button variant="text" onClick={handleClickOpen}  color="primary">Add</Button> 
       
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
     //       const date = formJson.date;
            handleAddOrEdit(formJson);
          },
        }}
      >
        <DialogTitle>Session Maintanence</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add or Edit a Session
          </DialogContentText>
          <TextField  InputProps={{readOnly: true }} required margin="dense" id="Date" label="Date" name="Date"  type="text" value={props.rowSession.Date}  fullWidth variant="outlined" />
          <TextField autoFocus required margin="dense" id="Description"  label="Description" name="Description"  type="text" fullWidth  variant="outlined" />
          <TextField autoFocus required margin="dense" id="Time"  label="Time" name="Time"  type="text" fullWidth variant="outlined" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" >Accept</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}