import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function handleAccept(jo) {

    // Code to handle login goes here
    props.acceptCallBack(props.rowingSession)
    handleClose();

  }
  return (
    <React.Fragment>
      <IconButton onClick={() => handleClickOpen()} variant="outlined" edge="start" aria-label="delete">
        <DeleteIcon htmlColor='white' />
      </IconButton>


      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            //       const date = formJson.date;
            handleAccept(formJson);
          },
        }}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you Sure you want to continue and delete {props.rowingSession.FirstName + " " + props.rowingSession.LastName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" >YES</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}