import * as React from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import  {SERVERURI, SERVERURI_PORT } from './constants'

export default function FormDialog(props) {
  //props should contain a default session if EDIT props.
  // 
  
  //const SERVERURI_PORT = 'http://132.145.211.115:3001';
  //const SERVERURI_PORT= 'http://localhost:3001';


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {

    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
    props.openHandler();
  };

  const purgeRowSessions = async () => {
    var {data} = await axios(SERVERURI_PORT+"/rowsessions/deleteOldRowSessions/").catch((err) => console.log(err));
    alert(JSON.stringify(data));
    var { data } = await axios(SERVERURI_PORT+"/peoplesignedup/deleteOldSignUps").catch((err) => console.log(err));
    alert(JSON.stringify(data));

  }


  function handlePurge(jo) {
    // Code to handle login goes here
    handleClose();
    //props.acceptCallBack(jo)
    purgeRowSessions();
    
  }
  return (
    <React.Fragment>

      <MenuItem onClick={handleClickOpen}>Purge Old Data...</MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            //       const date = formJson.date;
            handlePurge(formJson);
          },
        }}
      >
        <DialogTitle>Admin Settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to purge past data?
          </DialogContentText>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" >Accept</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}